import React from 'react';

const SelectInput = ({ handleChange, label, ...otherProps }) => (
    <div className="form-row">
        {label ? (
            <label htmlFor={otherProps.id}>
                {label}
            </label>
        ) : null}
        <select
            className="form-input"
            data-customfield={otherProps.customfieldid}
            onChange={handleChange}
            {...otherProps}
        >
            {otherProps.options.map((child, index) => (
                <option
                    key={index}
                    value={child.value}
                    selected={otherProps.value === child.value}
                >
                    {child.label}
                </option>
            ))}
        </select>
    </div>
);

export default SelectInput;
