import galabApi from "../api/Galab.instance";

export const TRANSITION_PLACE_ORDER = 'place_order';

export const BASE_ORDER = {
    name : '',
    articleNumber : '',
    batchNumber: '',
    customerOrderNumber: '',
    orderDetails: '',
    customerProductNumber: '',
    comment: '',
    bestBefore : '',
    state: '',
    token: '',
    customFieldData: [],
    analysisServiceData: [],
    isOrganic: true
};

export const getOrderById = (id) => {
    return galabApi.get('order_requests/'+id).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};

export const ORDER_STATES = {
    incomplete: 'Unvollständig',
    created: 'Angelegt',
    synced: 'Angelegt',
    arrived: 'Eingetroffen',
    in_progress: 'In Bearbeitung',
    in_clearance: 'In Klärung',
    completed: 'Abgeschlossen'
};

export const getStatusTitleByState = (name) => {
    return ORDER_STATES[name];
};

export const getStatusClassByState = (name) => {
    if(name === 'arrived') { return 'info'; }
    if(name === 'in_progress') { return 'warning'; }
    if(name === 'in_clearance') { return 'error'; }
    if(name === 'completed') { return 'success'; }

    return name;
};
