import React, { Component } from 'react';
import Moment from 'moment';

import FormInput from '../components/FormInput';
import TextareaInput from '../input/TextareaInput';

import { getLabelByName } from '../utils/Forms';
import SelectInput from "./SelectInput";

class OrderDetailForm extends Component {
    constructor(props) {
        super(props);
    }

    getCustomFieldValueById = (id) => {
        let value = '';

        if(id !== null && this.props.order.customFieldData.length > 0) {
            value = this.props.order.customFieldData.filter(obj => {

                return obj.id === id

            })[0].fieldValue;
        }

        return value;
    };

    render() {
        let order = this.props.order;
        let today = new Date();

        return (
            <form>
                <div className="form-row">
                    <div className="job-desc-wrap">
                        <label htmlFor="product_name">
                            <i className="icon-pen"></i>
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={this.props.handleChange}
                            value={order.name}
                            placeholder={getLabelByName('name') + ' *'}
                        />
                        <i className="icon-information-outline"></i>
                    </div>
                </div>
                <FormInput
                    id='customerOrderNumber'
                    name='customerOrderNumber'
                    type='text'
                    handleChange={this.props.handleChange}
                    value={order.customerOrderNumber}
                    label={getLabelByName('customerOrderNumber')}
                />
                <FormInput
                    id='orderDetails'
                    name='orderDetails'
                    type='text'
                    handleChange={this.props.handleChange}
                    value={order.orderDetails}
                    label={getLabelByName('orderDetails') + ' *'}
                    required
                />
                <FormInput
                    id='customerProductNumber'
                    name='customerProductNumber'
                    type='text'
                    handleChange={this.props.handleChange}
                    value={order.customerProductNumber}
                    label={getLabelByName('customerProductNumber')}
                />
                <FormInput
                    id='batchNumber'
                    name='batchNumber'
                    type='text'
                    handleChange={this.props.handleChange}
                    value={order.batchNumber}
                    label={getLabelByName('batchNumber') + ' *'}
                    required
                />
                <TextareaInput
                    id='comment'
                    name='comment'
                    type='text'
                    handleChange={this.props.handleChange}
                    value={order.comment}
                    label={getLabelByName('comment')}
                    required
                />
                {/*<FormInput*/}
                {/*    id='bestBefore'*/}
                {/*    name='bestBefore'*/}
                {/*    type='date'*/}
                {/*    min={today.toISOString().slice(0,10)}*/}
                {/*    handleChange={this.props.handleChange}*/}
                {/*    value={Moment(order.bestBefore).format('YYYY-MM-DD')}*/}
                {/*    label={getLabelByName('bestBefore')}*/}
                {/*/>*/}
                <SelectInput
                    id='isOrganic'
                    name='isOrganic'
                    handleChange={this.props.handleChange}
                    value={order.isOrganic}
                    label={getLabelByName('isOrganic') + ' *'}
                    options={[
                        { value: true, label: 'Ja, diese Probe ist Bio' },
                        { value: false, label: 'Nein, diese Probe ist kein Bio' }
                    ]}
                    required
                />
                {
                    this.props.customFieldData.map(({ id, fieldType, name }) => {
                        let customFieldValue = this.getCustomFieldValueById(id);
                        switch (fieldType) {
                            case 'text_area':
                                return <TextareaInput key={id}
                                    id={'customfield_' + id}
                                    customfieldid={id}
                                    name={name}
                                    handleChange={this.props.handleChange}
                                    value={customFieldValue}
                                    label={name}
                                />
                            default:
                                return <FormInput key={id}
                                    id={'customfield_' + id}
                                    customfieldid={id}
                                    name={id}
                                    type="text"
                                    handleChange={this.props.handleChange}
                                    value={customFieldValue}
                                    label={name}
                                />
                            }
                    }
                    )
                }
                <div className="form-row">
                    * mit dem Stern markierte Felder sind Pflichtfelder. Ihnen fehlen Eingabefelder? <a href="mailto:info@galab.de">Jetzt kontaktieren</a>
                </div>
            </form>
        )
    }
}

export default OrderDetailForm;
