import React, { Component } from 'react';

import OrderMetaData from '../components/OrderMetaData';
import ServiceList from '../components/ServiceList';
import Moment from "moment/moment";

class OrderPreview extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { order } = this.props;

        return (
            <div className="use-space scrollable order-preview-table">
                <div className="content-inner">
                    <h2 className="txtcenter">Zusammenfassung</h2>
                    <div className="infobox txtcenter">
                        <strong>“{order.name}”</strong>
                    </div>
                    <OrderMetaData order={order} />
                    <strong>Produkte ({order.analysisServiceData.length})</strong>
                </div>
                <div>
                    <ServiceList
                        items={order.analysisServiceData}
                        selectedItems={[]}
                        handleList={this.props.handleList}
                        handleListType='remove'
                    />
                </div>
                <div className="order-preview-table-footer">
                    <div className="content-inner">
                        <div className="order-preview-table-footer-input">
                            <strong>Ihre GALAB Probennummer</strong>
                            <input
                                type="text"
                                value={order.articleNumber || '-'}
                                readOnly
                            />
                        </div>
                        <div className="order-preview-table-footer-input">
                            <strong>Voraussichtlicher Abschlusstermin</strong>
                            <input
                                type="text"
                                value={order.estimatedCompletionDate ? Moment(order.estimatedCompletionDate).format('DD.MM.YYYY') : '-'}
                                readOnly
                            />
                        </div>
                        <div className="order-preview-table-footer-info">
                            Speichern Sie zuerst Ihren Auftrag, um Ihre GALAB Probennummer und einen voraussichtlichen Abschlusstermin zugeteilt zu bekommen.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderPreview;
