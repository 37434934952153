import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';

import Filter from "../components/Filter";
import Limit from "../components/Limit";
import ListRow from "../components/ListRow";
import ListRowSkeleton from "../components/ListRowSkeleton";
import Modal from "../components/Modal";
import Paging from "../components/Paging";
import OrderDetail from '../components/OrderDetail';
import CreateOrderForm from '../components/CreateOrderForm';
import FullScreenLoader from '../components/FullScreenLoader';

import { getUrlParameter } from '../utils/Common';
import { getOrderById } from '../utils/Order';

import galabApi from "../api/Galab.instance";

const BASE_PAGING = {
    'hydra:first': null,
    'hydra:last': null,
    'hydra:next': null,
    'hydra:previous': null,
};

class List extends Component {
    constructor(props) {
        super(props);

        let defaultStateFilter = [
            // 'incomplete',
            // 'created',
            // 'arrived',
            // 'in_progress',
            // 'in_clearance'
        ];

        this.state = {
            showLoading: 'Lade Aufträge',
            orders: {},
			orderDetail: null,
			orderDetailId: null,
			orderDetailObj: null,
            orderEdit: null,
            orderEditId: null,
            orderFormButtonText: 'Bearbeitung abschließen',
            //ordersFiltered: {},
            //ordersFilterQuery: '',
            createdAtSortIconClass: 'menu',
            paging: BASE_PAGING,
            filter: {
                status: defaultStateFilter,
            },
            params: {
                page: 1,
                'order[createdAt]': '',
                search: '',
                state: defaultStateFilter.join('|'),
                limit: 30,
            },
        };

        this.loadPagedOrdersFromApi = this.loadPagedOrdersFromApi.bind(this);
    }

    componentDidMount() {
        this.loadOrdersFromApi();
    }

    loadOrdersFromApi() {
        this.setState({
            showLoading: 'Lade Aufträge'
        });

        galabApi.get(
            'order_requests',
            {
                params: this.state.params
            }
        ).then(response => {
            this.setState({
                showLoading: false,
                orders: response.data['hydra:member'],
                paging: {
                    ... BASE_PAGING,
                    ... response.data['hydra:view']
                }
            });
        });
    }

    loadPagedOrdersFromApi = (url) => {
        let currentPageNumber = getUrlParameter('page', url);

        this.setState(prevState => ({
            params: {
                ... prevState.params,
                page: currentPageNumber,
            }
        }), this.loadOrdersFromApi);

        /* // use the url string given by api plattform

        this.setState({
            showLoading: 'Lade Aufträge'
        });

        let pagingUrl = url.replace('/api/', '');

        galabApi.get(pagingUrl).then(response => {
            this.setState({
                showLoading: false,
                orders: response.data['hydra:member'],
                paging: {
                    ... BASE_PAGING,
                    ... response.data['hydra:view']
                }
            })
        });
        */
    }

    handleCreatedSort = () => {
        let currentSort = this.state.params['order[createdAt]'];

        let newSort = '';
        let newSortIcon = 'menu';

        if(currentSort === '') {
            newSort = 'desc';
            newSortIcon = 'menu-down';
        } else if(currentSort === 'desc') {
            newSort = 'asc';
            newSortIcon = 'menu-up';
        }

        this.setState(prevState => ({
            createdAtSortIconClass: newSortIcon,
            params: {
                ... prevState.params,
                page: 1,
                'order[createdAt]': newSort,
            }
        }), this.loadOrdersFromApi);
    }

    handleStatusFilter = (status) => {
        let currentStatusFilter = this.state.filter.status;

        if(currentStatusFilter.includes(status, 0)) {
            // remove
           currentStatusFilter.splice(currentStatusFilter.indexOf(status), 1);
        } else {
            // push
            currentStatusFilter.push(status);
        }

        this.setState(prevState => ({
            filter: {
                ... prevState.filter,
                status: currentStatusFilter,
            },
            params: {
                ... prevState.params,
                page: 1,
                state: currentStatusFilter.join('|'),
            }
        }), this.loadOrdersFromApi);
    };

    handleLimit = (value) => {
        console.log(value);
        this.setState(prevState => ({
            params: {
                ... prevState.params,
                page: 1,
                limit: value,
            }
        }), this.loadOrdersFromApi);
    };

    copyOrder = (id)=>{
        if(id !== null) {
            let copyOrder = null;

            getOrderById(id)
                .then(function (response) {
                    copyOrder = {...response.data};

                    delete copyOrder.id;
                    delete copyOrder.articleNumber;
                    delete copyOrder.delivery_date;

                    let orderName = copyOrder.name;

                    copyOrder.name = orderName + ' (Kopie)';

                    this.setState({
                        orderEdit: copyOrder,
                        orderFormButtonText: 'Bearbeitung abschließen',
                    });
                }.bind(this))
                .catch(error => console.error('Outside error:', error))
        } else {
            this.setState({
                orderEdit: null,
            });

            this.loadOrdersFromApi();
        }
    };

    viewOrder = (id)=>{
        if(this.state.orderDetail === null) {
            getOrderById(id)
                .then(function(response) {
                    this.setState({
                        orderDetail: {...response.data},
                    });
                }.bind(this))
                .catch(error => console.error('Outside error:', error))
        } else {
            this.setState({
                orderDetail: null,
            });
        }
    };

    editOrder = (id)=>{
        // let order = this.getOrderById(id);

        // console.log(order);

        if(id === null) {
            this.loadOrdersFromApi();

            this.setState({
                orderEditId: null,
                orderEdit: null,
            })
        } else {
            this.setState({
                orderEditId: id,
                orderFormButtonText: 'Bearbeitung abschließen',
            })

        }
    };

    deleteOrder = (id, name, articleNumber) => {
        let confirmMsg = 'Wollen Sie den Auftrag "#' + id + '" wirklich löschen?';
        let successMsg = 'Ihr Auftrag wurde gelöscht!';

        if(name !== null && articleNumber !== null) {
            confirmMsg = 'Wollen Sie den Auftrag "' + name + ' (#'+articleNumber+')" wirklich löschen?';
            successMsg = 'Ihr Auftrag "' + name + ' (#'+articleNumber+')" wurde gelöscht!';
        }

        if(id !== null) {
            if (window.confirm(confirmMsg)) {
                galabApi.delete('order_requests/' + id).then(response => {
                    this.loadOrdersFromApi();
                    this.setState({
                        orderDetail: null,
                    });
                    toast.success(successMsg);
                }).catch(error => {
                    /*
                    if (error.response.status === 401) setError(error.response.data.message);
                    else setError("Something went wrong. Please try again later.");
                    */
                });
            }
        }
    };

    addOrder = (order) => {
        //console.log(order.id);
    };

    handleFilterList = (event) => {
        const { value } = event.target;

        this.setState(prevState => ({
            // ordersFilterQuery: value,
            filter: {
                ... prevState.filter,
                search: value,
            },
            params: {
                ... prevState.params,
                search: value,
            }
        }));
    };

    submitFilterList = () => {
        this.loadOrdersFromApi();
    };

	render() {
		const { orders, orderDetail, orderDetailId, orderEdit, orderEditId, filter, paging } = this.state;

		return (
			<div>
                <div className="max-height-content-container">
                    <div className="flex-grid-container">
                        <div className="w-100">
                            <div className="content-inner use-space parent scrollable-parent">
                                <Filter
                                    filter={filter}
                                    handleChange={this.handleFilterList}
                                    handleSubmit={this.submitFilterList}
                                    handleStatusFilter={this.handleStatusFilter}
                                />
                                <div className="table-wrap use-space scrollable">
                                    <table className="table table-striped table-bordered table-hover sticky-header">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Probenbezeichnung</th>
                                                <th width="210px">
                                                    <span className="ui-sort" onClick={this.handleCreatedSort}>
                                                        Erstellungsdatum <i className={'icon-' + this.state.createdAtSortIconClass}></i>
                                                    </span>
                                                </th>
                                                <th width="160px">Status</th>
                                                <th width="160px">Lieferdatum</th>
                                            </tr>
                                        </thead>
                                        {this.state.showLoading !== false ?
                                            <tbody>
                                                <ListRowSkeleton width="40%" />
                                                <ListRowSkeleton width="60%" />
                                                <ListRowSkeleton width="40%" />
                                                <ListRowSkeleton width="60%" />
                                                <ListRowSkeleton width="60%" />
                                                <ListRowSkeleton width="40%" />
                                            </tbody>
                                            :
                                            <tbody>
                                                {orders.length > 0 ?
                                                    orders.map(({ id, ...otherOrderProps }) => (
                                                        <ListRow
                                                            key={id} id={id} {...otherOrderProps}
                                                            viewHandle={this.viewOrder}
                                                            previewHandle={this.viewOrderPreview}
                                                            editHandle={this.editOrder}
                                                            copyHandle={this.copyOrder}
                                                            deleteHandle={this.deleteOrder} />
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan="5" style={{background: 'white'}}>
                                                            <div className="content-inner txtcenter">
                                                                <h2 className="offset-bottom-1">Es wurden keine Aufträge gefunden!</h2>
                                                                <Link to="/index/orders/add" className="button">
                                                                    Neuen Auftrag anlegen
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        }
                                    </table>
                                </div>
                                <Paging
                                    paging={paging}
                                    page={this.state.params.page}
                                    pagingHandle={this.loadPagedOrdersFromApi}
                                />
                                <Limit
                                    limit={this.state.params.limit}
                                    limitHandle={this.handleLimit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
				{orderDetailId !== null || orderDetail !== null ?
                    <Modal closeHandle={this.viewOrder}>
                        <OrderDetail
                            order={orderDetail}
                            orderId={orderDetailId}
                            editHandle={this.editOrder}
                            copyHandle={this.copyOrder}
                            deleteHandle={this.deleteOrder}
                        />
                    </Modal>
                : null }
                {orderEditId !== null ?
                    <Modal closeHandle={this.editOrder}>
                        <CreateOrderForm orderId={orderEditId} buttonText={this.state.orderFormButtonText} addHandle={this.addOrder} closeHandle={this.editOrder} />
                    </Modal>
				: null }
                {orderEdit !== null ?
                    <Modal closeHandle={this.editOrder}>
                        <CreateOrderForm order={orderEdit} buttonText={this.state.orderFormButtonText} addHandle={this.addOrder} closeHandle={this.editOrder} />
                    </Modal>
				: null }
                {false && this.state.showLoading !== false &&
                    <FullScreenLoader msg={this.state.showLoading} />
                }
			</div>
		)
	}
}

export default List;
